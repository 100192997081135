import { encrypt, decrypt } from "../utils/crypto";
import { notify } from "../utils/notify";
import { Auth } from "aws-amplify";
const URL = process.env.REACT_APP_SOCKET_URL;
const axios = require("axios");
const moment = require("moment");
const uuid = require("uuid");
const $ENV = process.env.env;

export default class Methods {
  // * @DESC Interns Component Methods * //

  static async createIntern(intern, emailParams, setCurrentTab, sendEmail) {
    try {
      await axios({
        url: `${URL}/interns`,
        method: "POST",
        data: {
          intern: intern,
          emailParams: emailParams,
          sendEmail: sendEmail,
        },
      });

      notify(
        `La solicitud de documentación de ${intern.firstname} ha comenzado`,
        "success"
      );
      setCurrentTab("internList");
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async createInternUploads(intern, emailParams) {
    try {
      await axios({
        url: `${URL}/interns`,
        method: "POST",
        data: { intern: intern, emailParams: emailParams, sendEmail: false },
      });
      return false;
    } catch (error) {
      console.log(error);
      error.response && (intern.error = error.response.data.message);
      return true;
    }
  }

  static async sendExternalEmail(intern, emailParams) {
    try {
      await axios({
        url: `${URL}/interns/sendEmail`,
        method: "POST",
        data: { intern: intern, emailParams: emailParams },
      });
      notify(`El formulario ha sido enviado al practicante`, "success");
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async sendExternalEmailApp(intern, emailParams) {
    try {
      await axios({
        url: `${URL}/interns/sendEmailApp`,
        method: "POST",
        data: { intern: intern, emailParams: emailParams },
      });
      notify(`El formulario ha sido enviado al practicante`, "success");
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updateIntern(data) {
    try {
      await axios({
        url: `${URL}/interns`,
        method: "PATCH",
        data: data,
      });
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updateInternUploads(intern, data) {
    try {
      await axios({
        url: `${URL}/interns`,
        method: "PATCH",
        data: data,
      });
      return false;
    } catch (error) {
      console.log(error);
      error.response && (intern.error = error.response.data.message);
      return true;
    }
  }

  static async createSettings(data) {
    try {
      await axios({
        url: `${URL}/settings`,
        method: "POST",
        data: { settings: data },
      });
    } catch (error) {
      console.log(error);
    }
  }

  static async updateSetting(data) {
    try {
      await axios({
        url: `${URL}/settings`,
        method: "PATCH",
        data: data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  static async getSettingsByIntern(data) {
    let response;
    try {
      response = await axios({
        url: `${URL}/settings/intern`,
        method: "POST",
        data: { INTERN: data },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  static async getInternsByClabe(data) {
    let response;
    try {
      response = await axios({
        url: `${URL}/interns/clabe`,
        method: "POST",
        data: { CLABE: data },
      });
      return response;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getInternsByCurp(curp, companyId) {
    let response;
    try {
      response = await axios({
        url: `${URL}/interns/curp`,
        method: "POST",
        data: {
          CURP: curp,
          COMPANY_ID: companyId,
        },
      });
      return response;
    } catch (error) {
      return {
        data: {
          total: 0,
          interns: [],
        },
      };

      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getInternsByEmail(data) {
    let response;
    try {
      response = await axios({
        url: `${URL}/interns/email`,
        method: "POST",
        data: { EMAIL: data },
      });
      return response;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getAllUsersByEmail(data) {
    let response;
    try {
      response = await axios({
        url: `${URL}/users/email`,
        method: "POST",
        data: { EMAIL: data },
      });
      return response;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async setPolicies(data) {
    let response;
    try {
      response = await axios({
        url: `${URL}/interns/setPolicies`,
        method: "PUT",
        data: data,
      });
      return response;
    } catch (error) {
      console.log(error, response);
      error.response && notify(error.response.data.message, "error");
      return error.response;
    }
  }

  static async resupplyPolicies(data) {
    let response;
    try {
      response = await axios({
        url: `${URL}/insurance/resupplyPolicies`,
        method: "PUT",
        data: data,
      });
      return response;
    } catch (error) {
      console.log(error, response);
      error.response && notify(error.response.data.message, "error");
      return error.response;
    }
  }

  static async updateInsurance(data) {
    try {
      await axios({
        url: `${URL}/insurance/user/update`,
        method: "PATCH",
        data: data,
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getInternsByIdCompany(id_company) {
    try {
      const response = await axios({
        url: `${URL}/interns`,
        method: "PUT",
        data: { id_company: id_company },
      });

      return response;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getPolicyById(data) {
    try {
      const response = await axios({
        url: `${URL}/insurance/get`,
        method: "POST",
        data: data,
      });

      return response.data;
    } catch (error) {
      error.response && console.log(error.response.data.message);
    }
  }

  static async getPolicyByIdUploads(intern, data) {
    try {
      const response = await axios({
        url: `${URL}/insurance/get`,
        method: "POST",
        data: data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      error.response && (intern.error = error.response.data.message);
      return [];
    }
  }

  static async getInternInsurance(data) {
    try {
      const response = await axios({
        url: `${URL}/insurance/user`,
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updateInternInsurance(data) {
    try {
      await axios({
        url: `${URL}/insurance/user/update`,
        method: "PATCH",
        data: data,
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async createInternInsurance(data) {
    try {
      let response = await axios({
        url: `${URL}/createInsurance/intern`,
        method: "POST",
        data: data,
      });
      return response;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async sendInsurance(data) {
    try {
      let response = await axios({
        url: `${URL}/insurance/send`,
        method: "POST",
        data: data,
      });
      return response;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async createInsuranceItem(intern, insurance) {
    try {
      await axios({
        url: `${URL}/insuranceRecord/create`,
        method: "POST",
        data: { insuranceData: insurance },
      });
      return false;
    } catch (error) {
      console.log(error);
      error.response && (intern.error = error.response.data.message);
      return true;
    }
  }

  static async deleteInsuranceLocal(data) {
    try {
      const response = await axios({
        url: `${URL}/insurance/local`,
        method: "DELETE",
        data: data,
      });
      return response.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async registerSifeInternByEmail(data) {
    try {
      const response = await axios({
        url: `${URL}/register/sife`,
        method: "POST",
        data: encrypt(data),
      });

      notify(response.data.message, "success");
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updateProfilePictureUrl(path, internId) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/interns/profile-picture/`,
        data: { path, internId },
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getAllContractsByDateRange(companyId, startDateAux, endDateAux) {
    let startDate = moment(startDateAux).subtract(1, "days").format("X");
    let endDate = moment(endDateAux).subtract(1, "days").format("X");

    let endDateToEvaluate = moment(endDateAux).subtract(1, "days");
    let startDateToEvaluate = moment(startDateAux).subtract(1, "days");
    let datesToSend = [];

    if (
      moment(endDateToEvaluate).diff(
        moment(startDateToEvaluate),
        "months",
        true
      ) > 3
    ) {
      while (
        moment(endDateToEvaluate).diff(moment(startDate, "X"), "months", true) >
        3
      ) {
        let startDate3Months = moment(endDateToEvaluate).subtract(
          "3",
          "Months"
        );
        datesToSend.push({
          endDate: endDateToEvaluate.format("X"),
          startDate: startDate3Months.format("X"),
          endDateLabel: moment(endDateToEvaluate).format(),
          startDateLabel: moment(startDate3Months).format(),
        });
        endDateToEvaluate = moment(endDateToEvaluate)
          .subtract("3", "months")
          .subtract("1", "day");
      }
      datesToSend.push({
        endDate: moment(endDateToEvaluate).format("X"),
        startDate: startDate,
        endDateLabel: moment(endDateToEvaluate, "X").format(),
        startDateLabel: moment(startDate, "X").format(),
      });
    } else {
      datesToSend.push({
        endDate: endDate,
        startDate: startDate,
        endDateLabel: moment(endDateToEvaluate, "X").format(),
        startDateLabel: moment(startDateToEvaluate, "X").format(),
      });
    }

    try {
      let data = [];
      let promises = [];
      for (let dates of datesToSend) {
        promises.push(
          axios({
            url: `${URL}/range/contract/${companyId}/${dates.startDate}/${dates.endDate}`,
            method: "GET",
          })
        );
      }

      let result = await Promise.all(promises);

      for (let status of result) {
        data = data.concat(status.data.contracts);
      }

      if (data?.length == 0) {
        notify(
          "No se encontraron practicantes en las fechas seleccionadas",
          "error"
        );
      }

      return data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return [];
    }
  }

  static async getAllInternsByStatusReport(idCompany, currentStatus) {
    let status = ["Activo", "Inactivo", "En proceso", "Pendiente"];
    let promises = [];
    let totalInterns = [];
    let result = [];
    try {
      if (currentStatus == "Todo") {
        for (let statusAux of status) {
          promises.push(
            axios({
              url: `${URL}/internsReport/${idCompany}/${statusAux}`,
              method: "GET",
            })
          );
        }

        result = await Promise.all(promises);
        for (let status of result) {
          let dataIntern = JSON.parse(decrypt(status.data));
          if (dataIntern) {
            totalInterns = [...totalInterns, ...dataIntern];
          }
        }
      } else {
        result = await axios({
          url: `${URL}/internsReport/${idCompany}/${currentStatus}`,
          method: "GET",
        });
        totalInterns = JSON.parse(decrypt(result.data));
      }

      if (!totalInterns.length) {
        notify("¡No hay practicantes en el estatus seleccionado!", "error");
        return false;
      }

      return totalInterns;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async updatePrefactureClabeByIntern(
    internId,
    color,
    lastModifyEmail,
    clabe
  ) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/clabe/update/prefacture`,
        data: { internId, color, lastModifyEmail, clabe },
      });
    } catch (error) {
      console.log(error);
    }
  }

  static async createInternLogisticRecord(body) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/logistics/records`,
        data: encrypt(body),
      });
    } catch (error) {
      console.log(error);
    }
  }

  static async getInternLogisticRecords({ internId }) {
    try {
      let response = await axios({
        method: "GET",
        url: `${URL}/logistics/records/${internId}`,
      });

      return JSON.parse(decrypt(response.data));
    } catch (error) {
      console.log(error);
    }
  }

  static async getInternsInProcessDashboard(companyId) {
    try {
      let response = await axios({
        method: "GET",
        url: `${URL}/dashboard/internsInProcess/${companyId}`,
      });
      return JSON.parse(decrypt(response.data));
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  static async getInternsToFinishDashboard(companyId) {
    try {
      let response = await axios({
        method: "GET",
        url: `${URL}/dashboard/internsToFinish/${companyId}`,
      });
      return JSON.parse(decrypt(response.data));
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  // * @DESC Cost Center Methods * //

  static async getCostCenters(currentDepartmentId, departmentField) {
    try {
      const response = await axios({
        url: `${URL}/costcenter`,
        method: "PUT",
        data: { id_department: currentDepartmentId, departmentField },
      });

      return response.data.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async createCostCenter(costCenter) {
    try {
      let response = await axios({
        url: `${URL}/costcenter`,
        method: "POST",
        data: costCenter,
      });

      notify(response.data.message, "success");
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  // * @DESC Departments  Methods * //

  static async createDepartment(data) {
    try {
      let response = await axios({
        url: `${URL}/departments`,
        method: "POST",
        data: data,
      });
      notify(response.data.message, "success");
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  // * @DESC Companies Component Methods * //

  static async startCompanyProcessFromRegister(data, userData) {
    try {
      await axios({
        url: `${URL}/companies`,
        method: "PATCH",
        data: encrypt(data),
      });

      await axios({
        url: `${URL}/users`,
        method: "PATCH",
        data: encrypt(userData),
      });

      notify("El proceso de registro de compañía ha comenzado", "success");
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async notifyCompanyCreation(data) {
    try {
      await axios({
        url: `${URL}/companies/notify`,
        method: "POST",
        data: encrypt(data),
      });

      notify(
        "Se notificó a los administradores sobre la creación de la compañia",
        "success"
      );
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async createCompany(data, userId, companies) {
    try {
      const response = await axios({
        url: `${URL}/companies`,
        method: "POST",
        data: encrypt(data),
      });

      const _id = response.data._id;

      const dataToBeUpdated = {
        _id: userId,
        attributes: [
          {
            updateKey: "companies",
            updateValue: [...companies, _id],
          },
        ],
      };

      await axios({
        url: `${URL}/users`,
        method: "PATCH",
        data: encrypt(dataToBeUpdated),
      });
      notify("La compañía se creó correctamente", "success");
      return response;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getCompanies(companiesArr) {
    try {
      const companies = await axios({
        url: `${URL}/companies/fromuser`,
        method: "PUT",
        data: encrypt({ companiesArr: companiesArr }),
      });

      const decrypted = await decrypt(companies.data);
      const parsed = JSON.parse(decrypted);
      return parsed;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getAllCompanies() {
    try {
      const companies = await axios({
        url: `${URL}/companies`,
        method: "GET",
      });
      const decrypted = decrypt(companies.data.companies);
      const companiesObject = companies.data.companiesObject;
      return {
        decrypted: JSON.parse(decrypted),
        companiesObject: companiesObject,
      };
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getAllCompaniesByPage(startKey, _page, _limit) {
    try {
      const page = _page || 1;
      const limit = _limit || 10;
      const data = { limit: limit };
      if (startKey) data.startKey = startKey;

      let response = await axios({
        url: `${URL}/companies/list/${page}`,
        method: "POST",
        data: data,
      });

      const decrypted = decrypt(response.data.companies);
      const pages = response.data.pages;
      const lastEvaluatedKey = response.data.lastEvaluatedKey;
      return JSON.parse(decrypted, pages, lastEvaluatedKey);
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getCompaniesByPage(companiesArr, startKey, _page, _limit) {
    try {
      const page = _page || 1;
      const limit = _limit || 10;
      const data = { limit: limit, companiesArr: companiesArr };
      if (startKey) data.startKey = startKey;

      let response = await axios({
        url: `${URL}/companies/user/list/${page}`,
        method: "POST",
        data: data,
      });

      const decrypted = decrypt(response.data.companies);
      const pages = response.data.pages;
      const lastEvaluatedKey = response.data.lastEvaluatedKey;
      return JSON.parse(decrypted, pages, lastEvaluatedKey);
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getPendingCompanies(_id) {
    try {
      const companies = await axios({
        url: `${URL}/companies/pending`,
        method: "GET",
      });

      const decrypted = decrypt(companies.data);
      const parsed = JSON.parse(decrypted);
      return parsed;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async approveCompany(_id, email, companyName) {
    const emailParams = {
      to: email,
      from: "practicantes@caintra.org.mx",
      subject: `FELICITACIONES - ${companyName} ha sido aprobada`,
      text: `Hola ¡Felicidades, tu compañía ha sido aprobada por los Administradores de Practicantes CAINTRA! En próximos días recibirás tu usuario y contraseña, así como un correo de bienvenida presentado a tu asesor de servicio.`,
    };

    try {
      await axios({
        url: `${URL}/companies`,
        method: "PATCH",
        data: encrypt({
          _id: _id,
          attributes: [
            {
              updateKey: "isApproved",
              updateValue: true,
            },
          ],
          emailParams: emailParams,
        }),
      });

      notify("¡Felicidades! La compañía se aprobó correctamente", "success");
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async uploadCompanyDoc(_id, file, name, key, lastDocId, isUrl) {
    const fileExtension = getExtension(file.name);
    const fileName = `${name}.${fileExtension}`;

    let data = {
      path: `companies/docs/${_id}/${fileName}`,
      _id: _id,
      fileName: fileName,
      fileExtension: fileExtension,
      fileKey: key,
    };

    if (file.size > 10485760) {
      notify(`El archivo ${file.name} pesa más de 10 MB`, "error");
      return;
    }

    if (lastDocId) {
      data.lastDocId = lastDocId;
    }
    if (isUrl) {
      data.isUrl = isUrl;
    }

    try {
      await Methods.getPresignedUrlAndPutImage(data, file);
      await axios({
        url: `${URL}/companies/docs`,
        method: "POST",
        data: data,
      });

      notify("Archivo subido exitosamente", "success");
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async uploadPrefactureDoc(
    _id,
    file,
    companyId,
    type,
    documentsNumber
  ) {
    let path = `companies/docs/${companyId}`;
    let extension = type == "facture" ? "pdf" : "xml";
    let fileName = `${type}_${_id}_${documentsNumber}.${extension}`;

    let data = {
      path: `${path}/${fileName}`,
      _id: _id,
      fileName: fileName,
      fileExtension: extension,
      fileKey: "Prefacture Doc",
    };

    if (file.size > 10485760) {
      notify(`El archivo ${file.name} pesa más de 10 MB`, "error");
      return;
    }

    try {
      await Methods.getPresignedUrlAndPutImage(data, file);
      notify("Archivo subido exitosamente", "success");
      return data.path;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getCompanyDocs(_id, setDocs) {
    try {
      const docs = await axios({
        url: `${URL}/companies/docs`,
        method: "PUT",
        data: encrypt({ company_id: _id }),
      });

      setDocs(docs.data.docs);
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updateCompany(data) {
    try {
      await axios({
        url: `${URL}/companies`,
        method: "PATCH",
        data: encrypt(data),
      });

      notify(
        "Los datos de esta compañía se actualizaron correctamente",
        "success"
      );
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async isRfcTaken(newRfc, _id) {
    try {
      let data = {
        _id: _id,
        rfc: newRfc,
      };
      let response = await axios({
        url: `${URL}/companies/rfc`,
        method: "POST",
        data: encrypt(data),
      });

      return response.data;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async startCompanyProcess(_id, company) {
    try {
      const emailParams = {
        to: "practicantes@caintra.org.mx",
        subject: `Registro - ${company} quiere solicitar acceso a la plataforma`,
        text: `¡Hola! Una nueva empresa quiere solicitar acceso a la plataforma, ¡revisa el detalle de Compañías en tu plataforma!`,
      };

      let data = {
        attributes: [
          {
            updateKey: "processFinished",
            updateValue: true,
          },
        ],
        _id: _id,
        emailParams: emailParams,
      };

      await axios({
        url: `${URL}/companies`,
        method: "PATCH",
        data: encrypt(data),
      });

      notify("¡El proceso de aprobación ha comenzado!", "success");
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async getUsersByCompany(
    companyId,
    defaultDepartments,
    customProjectionExpression
  ) {
    try {
      const response = await axios({
        url: `${URL}/users/${companyId}`,
        method: "PUT",
        data: {
          companyId: companyId,
          defaultDepartments,
          customProjectionExpression,
        },
      });
      const decrypted = decrypt(response.data);
      return JSON.parse(decrypted);
    } catch (error) {
      console.log(error);
    }
  }

  static async getCaintraAdminUsers() {
    try {
      const response = await axios({
        url: `${URL}/users/admins`,
        method: "PUT",
      });
      const decrypted = decrypt(response.data);
      return JSON.parse(decrypted);
    } catch (error) {
      console.log(error);
    }
  }

  static async getCaintraUsers(companyId) {
    try {
      let data = { _id: companyId };
      const response = await axios({
        url: `${URL}/users/caintra`,
        method: "PUT",
        data: encrypt(data),
      });
      const decrypted = decrypt(response.data);
      return JSON.parse(decrypted);
    } catch (error) {
      console.log(error);
    }
  }

  static async getApprovedUsersByCompany(companyId) {
    try {
      const response = await axios({
        url: `${URL}/users/approved`,
        method: "PUT",
        data: { companyId: companyId },
      });
      const decrypted = decrypt(response.data);
      return JSON.parse(decrypted);
    } catch (error) {
      console.log(error);
    }
  }

  static async registerUser(userData) {
    try {
      await axios({
        url: `${URL}/users`,
        method: "POST",
        data: encrypt(userData),
      });

      return true;
    } catch (error) {
      switch (error._name) {
        case "UsernameExistsException":
          notify("Este correo ya ha sido registrado", "error");
          break;
        default:
          error.response && notify(error.response.data.message, "error");
          break;
      }
    }
  }

  static async registerUserCognito(userData) {
    try {
      await axios({
        url: `${URL}/users/register`,
        method: "POST",
        data: encrypt(userData),
      });

      return true;
    } catch (error) {
      switch (error._name) {
        case "UsernameExistsException":
          notify("Este correo ya ha sido registrado", "error");
          break;
        default:
          error.response && notify(error.response.data.message, "error");
          break;
      }
    }
  }

  static async deleteUser(userId, email, currentDepartmentId) {
    let body = { userId: userId, email: email };
    if (currentDepartmentId) body.currentDepartmentId = currentDepartmentId;
    try {
      await axios({
        url: `${URL}/users`,
        method: "DELETE",
        data: encrypt(body),
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      console.log(error);
    }
  }

  static async deleteLeaderKey(userId, currentDepartmentId, generalistaField) {
    let body = {
      userId: userId,
      currentDepartmentId: currentDepartmentId,
      generalistaField: generalistaField,
    };
    try {
      await axios({
        url: `${URL}/users/leader`,
        method: "DELETE",
        data: encrypt(body),
      });
    } catch (error) {
      console.log(error);
    }
  }

  static async updateUser(data) {
    try {
      await axios({
        url: `${URL}/users`,
        method: "PATCH",
        data: encrypt(data),
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async adminRegisterUser(data) {
    try {
      await axios({
        url: `${URL}/users/admin-register`,
        method: "POST",
        data: encrypt(data),
      });
      notify("Usuario registrado exitosamente", "success");
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getUserByEmail(data) {
    try {
      let resp = await axios({
        url: `${URL}/users/verify`,
        method: "PUT",
        data: encrypt(data),
      });
      return resp;
    } catch (error) {
      console.log(error);
      error.data = {
        isApproved: false,
        isRegistered: false,
      };
      error.response && notify(error.response.data.message, "error");
      return error;
    }
  }

  static async getRoles(companyId) {
    try {
      const response = await axios({
        url: `${URL}/roles`,
        method: "PUT",
        data: { companyId: companyId },
      });

      const decrypted = decrypt(response.data);
      return JSON.parse(decrypted);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async createRole(data, isCustom) {
    try {
      await axios({
        url: `${URL}/roles`,
        method: "POST",
        data: encrypt(data),
      });

      if (isCustom) {
        notify("Rol creado con éxito", "success");
      }
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updateRole(data) {
    try {
      await axios({
        url: `${URL}/roles`,
        method: "PATCH",
        data: encrypt(data),
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async deleteRole(data) {
    try {
      await axios({
        url: `${URL}/roles`,
        method: "DELETE",
        data: encrypt(data),
      });

      notify(
        "Rol eliminado con éxito, los usuarios se han desasignado",
        "success"
      );
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  // * @DESC Prefacture methods * //

  static async getApprovedInterns(data, page) {
    try {
      return await axios({
        url: `${URL}/interns/list/prefacture/${page}`,
        method: "POST",
        data: data,
      });
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async getAllApprovedInterns(data, period, isInsuranceCompany) {
    let status = ["Activo", "Inactivo", "En proceso", "Pendiente"];
    let promises = [];
    let totalInterns = [];

    try {
      for (let statusAux of status) {
        promises.push(
          axios({
            url: `${URL}/interns/approved`,
            method: "POST",
            data: encrypt({
              id_company: data,
              currentPeriod: period,
              isInsuranceCompany: isInsuranceCompany,
              status: statusAux,
            }),
          })
        );
      }

      let result = await Promise.all(promises);

      for (let status of result) {
        let dataIntern = JSON.parse(decrypt(status.data));
        if (dataIntern) {
          totalInterns = [...totalInterns, ...dataIntern];
        }
      }

      if (!totalInterns.length) {
        notify("¡Aún no hay practicantes aprobados!", "error");
        return false;
      }

      return totalInterns;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async getHistoricalInterns(historicalInterns, pages) {
    let data = {
      historicalInterns: historicalInterns,
    };
    try {
      let response = await axios({
        url: `${URL}/interns/historical/list/${pages}`,
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async getInternById(_id) {
    let data = {
      _id: _id,
    };
    try {
      let response = await axios({
        url: `${URL}/intern/${_id}`,
        method: "POST",
        data: data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async getPrefactureByPeriod(period, companyId) {
    try {
      let response = await axios({
        url: `${URL}/prefactures/period`,
        method: "PUT",
        data: encrypt({ period: period, companyId: companyId }),
      });
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  static async updatePrefacture(data) {
    try {
      await axios({
        url: `${URL}/prefacture/intern`,
        method: "PATCH",
        data: data,
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getPeriodsByPrefacture(companyId, year, month) {
    try {
      let result = await axios({
        url: `${URL}/period/prefactures`,
        method: "PUT",
        data: encrypt({
          companyId: companyId,
          year: year,
          month: month,
        }),
      });
      if (result.data) {
        return result.data;
      } else {
        return undefined;
      }
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return undefined;
    }
  }

  static async getSubsequentPeriods(data) {
    try {
      let result = await axios({
        url: `${URL}/periods/subsequent`,
        method: "POST",
        data: data,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return [];
    }
  }

  static async getPreviousPeriods(data) {
    try {
      let result = await axios({
        url: `${URL}/periods/previous`,
        method: "POST",
        data: data,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return [];
    }
  }

  static async getApprovedPrefactures(currentPayDay, companiesIds) {
    try {
      const response = await axios({
        url: `${URL}/prefactures/${companiesIds[0]}`,
        method: "PUT",
        data: encrypt({
          companiesIds: companiesIds,
          currentPayDay: currentPayDay,
        }),
      });
      const decrypted = response.data;
      return decrypted;
    } catch (error) {
      console.log(error);
    }
  }

  static async createPrefacture(
    totalDual,
    countInterns,
    prefacture,
    companyName,
    prefactureData,
    userFullName,
    formattedStartDate,
    formattedEndDate,
    approvedInterns,
    payDay,
    createdAt,
    consolidationValidations,
    previusQMPrefacture,
    previusQMPrefactureDetails,
    companyId
  ) {
    console.log("CREATE -----", totalDual, countInterns);
    let forceApprovePeriod =
      consolidationValidations.forceApprovePeriod || false;
    if (!forceApprovePeriod) {
      if (
        (consolidationValidations.isSecondQMPeriod && previusQMPrefacture) ||
        consolidationValidations.isFirstQMPeriod
      ) {
        prefactureData.isQMPrefacture = true;
      }
      if (
        consolidationValidations.isFirstConsolidationPeriod ||
        consolidationValidations.isSecondConsolidationPeriod
      ) {
        prefactureData.isConsolidationPeriod = true;
      }
      prefactureData.isFirstConsolidationPeriod =
        consolidationValidations.isFirstConsolidationPeriod;
      prefactureData.isSecondConsolidationPeriod =
        consolidationValidations.isSecondConsolidationPeriod;
      prefactureData.isFirstQMPeriod = consolidationValidations.isFirstQMPeriod;
      prefactureData.isSecondQMPeriod =
        consolidationValidations.isSecondQMPeriod;
      prefactureData.isQMCPrefacture = consolidationValidations.isQMCPrefacture;
    } else {
      prefactureData.forceApprovePeriod =
        consolidationValidations.forceApprovePeriod;
    }

    try {
      await axios({
        url: `${URL}/prefactures`,
        method: "POST",
        data: {
          totalDual: totalDual,
          countInterns: countInterns,
          prefacture: prefacture,
          companyName: companyName,
          prefactureData: prefactureData,
          userFullName: userFullName,
          formattedStartDate: formattedStartDate,
          formattedEndDate: formattedEndDate,
          approvedInterns: approvedInterns,
          payDay: payDay,
          createdAt: createdAt,
          previusQMPrefacture,
          previusQMPrefactureDetails,
          previusQMPrefacture: previusQMPrefacture,
          companyId: companyId,
        },
      });

      notify("La prefactura se autorizó exitosamente", "success");
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async getDetailsByPrefacture(prefactureId) {
    try {
      let response = await axios({
        url: `${URL}/prefactures/details`,
        method: "PUT",
        data: { _id: prefactureId },
      });
      return response.data;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async cancelPrefacture(
    prefactureId,
    prefactureUrl,
    prefactureUrlXml,
    historicalPDF,
    historicalXML,
    documentsNumber
  ) {
    try {
      let response = await axios({
        url: `${URL}/prefactures/cancel`,
        method: "PUT",
        data: {
          _id: prefactureId,
          prefactureUrl: prefactureUrl,
          prefactureUrlXml: prefactureUrlXml,
          historicalPDF: historicalPDF,
          historicalXML: historicalXML,
          documentsNumber: documentsNumber,
        },
      });
      notify(response.data.message, "success");
      return true;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async uploadManually(
    prefactureId,
    prefactureUrl,
    prefactureUrlXml,
    invoiceAmount,
    invoiceNumber
  ) {
    try {
      let response = await axios({
        url: `${URL}/prefactures/upload`,
        method: "PUT",
        data: {
          _id: prefactureId,
          prefactureUrl: prefactureUrl,
          prefactureUrlXml: prefactureUrlXml,
          invoiceNumber,
          invoiceAmount,
        },
      });
      notify(response.data.message, "success");
      return true;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async rollBackPrefacture(interns) {
    try {
      let data = {
        interns: interns,
        attributes: [{ updateKey: "rolledBack", updateValue: true }],
      };
      const response = await axios({
        url: `${URL}/prefactures`,
        method: "PATCH",
        data: data,
      });

      notify(
        "El roll-back de la prefactura se autorizó exitosamente",
        "success"
      );
      return response;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async getContpaqAgent(agent) {
    try {
      const response = await axios({
        url: `${URL}/getContpaq/agent`,
        method: "POST",
        data: { id: agent },
      });
      return response.data;
    } catch (error) {
      return error;
    }
  }

  static async getContpaqStatus(facture) {
    try {
      const response = await axios({
        url: `${URL}/getContpaq/prefacture`,
        method: "POST",
        data: { id: facture },
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async getLastContractPaymentDays(intern, company_id, periodAux) {
    try {
      const response = await axios({
        url: `${URL}/lastContract/days/prefacture`,
        method: "PUT",
        data: {
          intern: intern,
          company_id: company_id,
          actualPeriod: periodAux,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error.response);
      return error.response.data;
    }
  }

  static async getHistoricalInsurancePayment(
    intern_id,
    company_id,
    currentDate,
    valuePayment
  ) {
    try {
      const response = await axios({
        url: `${URL}/payment/insurance`,
        method: "PUT",
        data: {
          intern_id: intern_id,
          company_id: company_id,
          currentDate: currentDate,
          valuePayment: valuePayment,
        },
      });
      return response.data.isPaid;
    } catch (error) {
      return error.response.data.isPaid;
    }
  }

  static async getLastPeriodLimitDate(currentPeriod, payment_period) {
    try {
      let result = await axios({
        url: `${URL}/last-period/limit`,
        method: "PUT",
        data: { currentPeriod: currentPeriod, payment_period: payment_period },
      });
      if (result.data) {
        return result.data[0];
      } else {
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  }

  static async setCompaqPrefacture(
    prefactureDetailId,
    purchaseOrder,
    idCompany,
    company,
    observations,
    authorizedInfo,
    period
  ) {
    try {
      let authDate = moment().format("DD/MM/YYYY");
      let factureOptions = [];
      console.log(company);
      const requiredKeys = [
        "facturePaymentType",
        "factureCFDI",
        "facturePaymentMethod",
        "factureAgent",
        "client_number",
        "social_reason",
      ];
      const [startDate, endDate] = period;
      const startPeriodCompaq = moment(startDate).format("DD/MM/YYYY");
      const endPeriodCompaq = moment(endDate).format("DD/MM/YYYY");
      const invoiceRecordID = uuid.v1();
      for (let key of requiredKeys) {
        if (!company[key]) {
          notify(
            "No se puede enviar a timbrar, por falta de datos, verifique con su agente",
            "error"
          );
          return;
        }
        factureOptions.push({ key: key, value: company[key] });
      }

      factureOptions.push({
        key: "startPeriodCompaq",
        value: startPeriodCompaq,
      });
      factureOptions.push({ key: "endPeriodCompaq", value: endPeriodCompaq });

      await axios({
        url: `${URL}/setCompaq/prefacture`,
        method: "POST",
        data: encrypt({
          prefactureDetailId,
          purchaseOrder,
          idCompany,
          factureOptions,
          authDate,
          observations,
          authorizedBy: authorizedInfo.authorName,
          authorizedByID: authorizedInfo.authorId,
          invoiceRecordData: {
            stampedAuthorName: authorizedInfo.authorName,
            stampedAuthorEmail: authorizedInfo.authorEmail,
            periodType: authorizedInfo.periodType,
            companyName: authorizedInfo.companyName,
            companyId: idCompany,
            createdAt: moment().format(),
            reportType: "Stamped",
            invoiceAmount: authorizedInfo.invoiceAmount,
            prefactureDetailId: prefactureDetailId,
            agentName: authorizedInfo.agentName,
            periodDates: authorizedInfo.periodDates,
            stampedDate: moment().format(),
          },
        }),
      });

      notify("Prefactura generada correctamente", "success");
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getPrefactureOptions() {
    try {
      let result = await axios({
        url: `${URL}/prefacture/options`,
        method: "GET",
      });
      let sortedOptions = result.data;
      sortedOptions.sort((a, b) => {
        if (a.satKey < b.satKey) {
          return -1;
        }
        if (a.satKey > b.satKey) {
          return 1;
        }
        return 0;
      });

      return sortedOptions;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getInvoiceRecordsByType(data) {
    let promises = [];
    let totalReports = [];
    try {
      let currentTypes = ["Stamped", "Authorization", "Dispersed"];
      if (data.reportType == "Todos" || data.customTypes) {
        currentTypes = data.customTypes || currentTypes;

        for (let type of currentTypes) {
          data.reportType = type;
          promises.push(
            axios({
              url: `${URL}/invoice/records`,
              method: "POST",
              data: encrypt(data),
            })
          );
        }
      } else {
        promises.push(
          axios({
            url: `${URL}/invoice/records`,
            method: "POST",
            data: encrypt(data),
          })
        );
      }

      let result = await Promise.all(promises);

      for (let status of result) {
        let dataReport = JSON.parse(decrypt(status.data));
        if (dataReport) {
          totalReports = [...totalReports, ...dataReport];
        }
      }

      if (!totalReports.length && !data.dontShowError)
        notify(
          "No se encontrarón reportes de las fechas seleccionadas",
          "error"
        );
      console.log(totalReports);
      for (let i = 0; i < totalReports.length; i++) {
        if (totalReports[i].companyName.toUpperCase().includes("DUAL")) {
          let response2 = await axios({
            url: `${URL}/prefactures/details`,
            method: "PUT",
            data: { _id: totalReports[i].prefactureDetailId },
          });
          console.log(response2);
          console.log(response2.data.periodStartDate);
          let fecha1 = new Date(
            response2.data.periodStartDate.split("/")[2],
            response2.data.periodStartDate.split("/")[1] - 1,
            response2.data.periodStartDate.split("/")[0]
          );
          let fecha2 = new Date(2024, 3, 30);
          console.log(fecha1.toString(), fecha2.toString());
          if (fecha1 >= fecha2) {
            console.log(
              "Fecha es mayor",
              response2.data.totalCost,
              response2.data.totalDual
            );
            totalReports[i].invoiceAmount =
              response2.data.totalCost +
              response2.data.totalDual +
              (response2.data.totalCost + response2.data.totalDual) * 0.16;
            console.log(totalReports[i]);
            totalReports[i].invoiceAmount = totalReports[i].invoiceAmount;
            console.log(totalReports);
          }
        }
      }

      console.log(totalReports);
      return totalReports;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  // * @DESC Records methods * //

  static async createRecord(record) {
    try {
      await axios({
        url: `${URL}/records`,
        method: "POST",
        data: encrypt({ record: record }),
      });
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async getRecords(companyId) {
    try {
      const response = await axios({
        url: `${URL}/records`,
        method: "PUT",
        data: encrypt({ companyId: companyId }),
      });

      const data = decrypt(response.data);
      const records = JSON.parse(data);

      return records;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  // * @DESC Dispersion methods* //

  static async getApprovedCompanies() {
    try {
      const response = await axios({
        url: `${URL}/companies/approved`,
        method: "GET",
      });

      const decrypted = decrypt(response.data);

      return JSON.parse(decrypted);
    } catch (error) {
      console.log(error);
      error.response && notify(error.reponse.data.message, "error");
    }
  }

  // * @DESC Email methods * //

  static async sendEmail(emailParams) {
    console.log(emailParams);
    try {
      await axios({
        url: `${URL}/email/send`,
        method: "POST",
        data: encrypt(emailParams),
      });
    } catch (error) {
      console.log(error);
      error.response && notify(error.reponse.data.message, "error");
    }
  }

  // * @DESC Signatures * //
  static async getSignatureByEmail(body) {
    try {
      const response = await axios({
        method: "PUT",
        url: `${URL}/get/signature`,
        data: body,
      });
      let dataDecripted = JSON.parse(decrypt(response.data));
      return dataDecripted.signature;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async notifyIntern(body) {
    try {
      const response = await axios({
        method: "PUT",
        url: `${URL}/signature/notify`,
        data: body,
      });
      return decrypt(response.data.signature);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getInternContract(id) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/contract/intern`,
        data: { currentContractId: id },
      });

      let dataDecripted = JSON.parse(decrypt(response.data));
      return dataDecripted;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getAllInternContracts(id) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/all/contracts/intern`,
        data: { internId: id },
      });

      let dataDecripted = JSON.parse(decrypt(response.data));
      return dataDecripted;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getContractById(_id) {
    let data = {
      _id: _id,
    };
    try {
      let response = await axios({
        url: `${URL}/contract/${_id}`,
        method: "POST",
        data: data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
      return false;
    }
  }

  static async getInternsNextPage(
    id_company,
    limit,
    isQuery,
    query,
    internStatus,
    currentPage,
    limitPages
  ) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/allInterns/status`,
        data: {
          id_company,
          limit,
          isQuery,
          query,
          internStatus,
          currentPage,
          limitPages,
        },
      });
      let dataDecripted = response.data;
      return dataDecripted;
    } catch (error) {
      console.log(error);
    }
  }

  static async getCountActiveInactiveinterns(
    id_company,
    onlyActives = false,
    onlyInactives = false
  ) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/interns/status/count`,
        data: { id_company, onlyInactives, onlyActives },
      });
      let dataDecripted = response.data;
      return dataDecripted;
    } catch (error) {
      console.log(error);
    }
  }

  static async updateContractById(body) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/update/contract/intern`,
        data: body,
      });

      let dataDecripted = response.data;
      return dataDecripted;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updateContractByIdUploads(intern, body) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/update/contract/intern`,
        data: body,
      });

      return false;
    } catch (error) {
      console.log(error);
      error.response && (intern.error = error.response.data.message);
      return true;
    }
  }

  static async massiveUploadNotification(body) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/uploads/notification`,
        data: body,
      });

      return false;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async renewIntern(body) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/renew/contract/intern`,
        data: body,
      });

      let dataDecripted = response.data;
      return dataDecripted;
    } catch (error) {
      console.log(error);
      error.response && notify(error.response.data.message, "error");
    }
  }

  // * @DESC S3 funcitons* //

  static async getPresignedUrlAndPutImage(body, image) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/s3/putObject`,
        data: body,
      });

      await axios({
        url: response.data.presignedPUTURL,
        method: "PUT",
        data: image,
        headers: {
          "Content-Type": verifyDocumentTypeHeader(body.fileExtension),
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async createGroup(body) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/groups`,
        data: body,
      });
      console.log(response.statusCode);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async deleteGroup(body) {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${URL}/groups`,
        data: body,
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getGroups() {
    try {
      const response = await axios({
        method: "GET",
        url: `${URL}/groups`,
      });
      return response.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getGroupItems(body) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/companies-rfc/groups`,
        data: body,
      });
      return response.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async addGroupItem(body) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/groups/items`,
        data: body,
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async deleteGroupItem(body) {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${URL}/groups/items`,
        data: body,
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getCompaniesByRFC(body) {
    try {
      const response = await axios({
        method: "GET",
        url: `${URL}/companies/${body.RFC}`,
        data: body,
      });
      return response.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getCompanyNamesByRFC(body) {
    try {
      const response = await axios({
        method: "GET",
        url: `${URL}/companies/names/${body.RFC}`,
        data: body,
      });
      return response.data.map((k) => k.business_name) || [];
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getAllGroupsItems(body) {
    try {
      const response = await axios({
        method: "GET",
        url: `${URL}/groups/items`,
        data: body,
      });
      return response.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async findRFCInGroup(body) {
    try {
      const { RFC } = body;
      const groups = await this.getAllGroupsItems();
      for (const group in groups) {
        if (groups[group].items[RFC] != undefined) {
          return groups[group].items;
        }
      }

      return null;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async changeGroupName(body) {
    try {
      const response = await axios({
        method: "PATCH",
        url: `${URL}/groups`,
        data: body,
      });
      console.log(response);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getCompaniesFromGroup(groupRFCs, currentCompanyRfc) {
    let promises = [];
    let companies = [];
    if (groupRFCs && groupRFCs.length) {
      for (let RFC of groupRFCs) {
        promises.push(Methods.getCompaniesByRFC({ RFC: RFC }));
        if (promises.length > 100) {
          (await Promise.all(promises)).map((result) =>
            companies.push(...result)
          );
          promises = [];
        }
      }

      (await Promise.all(promises)).map((result) => companies.push(...result));
    } else {
      companies = await Methods.getCompaniesByRFC({ RFC: currentCompanyRfc });
    }

    return companies;
  }

  static async getAllCompaniesName(companies) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/all-companies/name`,
        data: { companies },
      });

      return JSON.parse(decrypt(response.data));
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return [];
    }
  }

  static async createCostCenterTec(body) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/costcenters`,
        data: body,
      });
      console.log(response);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }
  static async getCostCentersTec(body) {
    try {
      const response = await axios({
        method: "GET",
        url: `${URL}/costcenters`,
        data: body,
      });
      return response.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }
  static async editCostCenterTec(body) {
    try {
      const response = await axios({
        method: "PATCH",
        url: `${URL}/costcenters`,
        data: body,
      });
      console.log(response);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }
  static async deleteCostCenterTec(body) {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${URL}/costcenters`,
        data: body,
      });
      console.log(response);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async createGeneralista(body) {
    try {
      const response = await axios({
        method: "POST",
        url: `${URL}/generalistas`,
        data: body,
      });
      console.log(response);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }
  static async getGeneralistas(body) {
    try {
      const response = await axios({
        method: "GET",
        url: `${URL}/generalistas`,
        data: body,
      });
      return response.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }
  static async editGeneralista(body) {
    try {
      const response = await axios({
        method: "PATCH",
        url: `${URL}/generalistas`,
        data: body,
      });
      console.log(response);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }
  static async deleteGeneralista(body) {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${URL}/generalistas`,
        data: body,
      });
      console.log(response);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  // * @DESC Logistics* //

  static async getInternsByLogisticRange(dateToEvaluate) {
    let promises = [];
    let totalInterns = [];
    let companies = [{}];
    try {
      [
        "Alta",
        "Confirmado",
        "Enviado",
        "En proceso",
        "Pausado",
        "Retenido",
      ].map((logisticStatus) => {
        promises.push(
          axios({
            method: "GET",
            url: `${URL}/logistics/${logisticStatus}/${parseInt(
              dateToEvaluate.format("X")
            )}`,
          })
        );
      });

      let result = await Promise.all(promises);

      for (let interns of result) {
        let dataIntern = JSON.parse(decrypt(interns.data));
        if (dataIntern) {
          for (let intern of dataIntern) {
            if (
              !companies.find(
                (company) => company.companyName == intern.companyName
              )
            ) {
              companies = [
                ...companies,
                {
                  companyName: intern.companyName,
                  bank: intern.internship_bank?.toLowerCase(),
                },
              ];
            }
          }
          totalInterns = [...totalInterns, ...dataIntern];
        }
      }
      companies = companies.sort((a, b) => {
        return a.companyName < b.companyName ? -1 : 1;
      });
      if (!totalInterns.length) {
        notify("¡Aún no hay practicantes aprobados!", "error");
        return false;
      }

      return { totalInterns, companies };
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return [];
    }
  }

  static async getLogisticOptions() {
    let promises = [];
    let optionsNames = [
      "ocupationCode",
      "livingTypeCode",
      "nationalityCode",
      "maritalStatus",
      "scholarshipCode",
      "stateCode",
      "zipCodeRange",
      "maritalStatusSantander",
      "nationalitySantander",
    ];
    let options = {
      ocupationCode: [],
      livingTypeCode: [],
      nationalityCode: [],
      maritalStatus: [],
      scholarshipCode: [],
      stateCode: [],
      zipCodeRange: [],
      maritalStatusSantander: [],
    };
    try {
      optionsNames.map((typeOption) => {
        promises.push(
          axios({
            method: "GET",
            url: `${URL}/logisticOptions/${typeOption}`,
          })
        );
      });

      let result = await Promise.all(promises);

      for (let optionsAux in result) {
        options[optionsNames[optionsAux]] = JSON.parse(
          decrypt(result[optionsAux].data)
        );
      }

      return options;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return [];
    }
  }

  static async getLogisticsReport(event) {
    let totalInterns = [];
    let promises = [];
    try {
      event.status.map((status) => {
        event.status = status;
        promises.push(
          axios({
            method: "POST",
            url: `${URL}/logistics/report`,
            data: event,
          })
        );
      });
      let result = await Promise.all(promises);
      for (let interns of result) {
        let dataIntern = JSON.parse(decrypt(interns.data));
        for (let data of dataIntern) {
          data.created_at = moment(data.created_at).format("DD/MM/YYYY");
          data.fullName =
            data.firstname +
            " " +
            data.father_lastname +
            " " +
            data.mother_lastname;
          totalInterns.push(data);
        }
      }

      if (!totalInterns.length) {
        notify("¡Aún no hay practicantes aprobados!", "error");
        return false;
      }
      return totalInterns;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return [];
    }
  }

  //* @DESC configurations* //

  static async getStampedStatus() {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/configurations/invoice`,
      });

      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return {};
    }
  }

  static async updateConfiguration(body, recordBody) {
    try {
      let response = await axios({
        method: "POST",
        url: `${URL}/configurations/update`,
        data: encrypt(body),
      });

      notify(response.data.message, "success");

      await axios({
        method: "POST",
        url: `${URL}/configurations/record`,
        data: encrypt(recordBody),
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async saveConfigurationRecord(body) {
    try {
      let response = await axios({
        method: "POST",
        url: `${URL}/configurations/record`,
        data: encrypt(body),
      });
      notify(response.data.message, "success");
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getAllPeriods(body) {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/configurations/periods/get/${body}`,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async savePeriods(body) {
    try {
      let response = await axios({
        method: "POST",
        url: `${URL}/configurations/periods/save`,
        data: body,
      });
      notify(response.data.message, "success");
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async deletePeriodYear(body) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/configurations/periods/delete`,
        data: body,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getInvoiceRecordsByPeriod(body) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/invoice/records/period`,
        data: body,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async saveApiData(body) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/configurations/api/save`,
        data: body,
      });
      notify(`Guardado de API - ${body.app} correcta`, "success");
    } catch (error) {
      notify(`Guardado de API - ${body.app} fallido`, "error");
    }
  }

  static async getApiData(body) {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/configurations/api/get/${body}`,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async testApi(body) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/configurations/api/test`,
        data: body,
      });
      notify(`Prueba de API - ${body} correcta`, "success");
    } catch (error) {
      if (body === "sife" && error.response.status === 404) {
        notify(`Prueba de API - ${body} correcta`, "success");
      } else {
        notify(`Prueba de API - ${body} fallida`, "error");
      }
    }
  }

  static async saveMail(body) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/configurations/mail/save`,
        data: body,
      });
      notify(`Guardado de correo de ${body.app} correcta`, "success");
    } catch (error) {
      notify(`Guardado de correo de ${body.app} fallido`, "error");
    }
  }

  static async getMail(body) {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/configurations/mail/get/${body}`,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async testMail(body) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/configurations/mail/test`,
        data: body,
      });
      notify(`Prueba de correo de ${body} correcta`, "success");
    } catch (error) {
      notify(`Prueba de correo de ${body} fallida`, "error");
    }
  }

  static async getAmountContacts() {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/configurations/amount/get`,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async addAmountContacts(body) {
    try {
      for (let contact of body) {
        await axios({
          method: "POST",
          url: `${URL}/configurations/amount/add`,
          data: contact,
        });
      }
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updateAmountContacts(body) {
    try {
      for (let contact of body) {
        await axios({
          method: "POST",
          url: `${URL}/configurations/amount/update`,
          data: contact,
        });
      }
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }
  static async deleteAmountContact(body) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/configurations/amount/delete`,
        data: body,
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getDispersionNumber() {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/configurations/dispersion/get`,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updateDispersionNumber(body) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/configurations/dispersion/update`,
        data: body,
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async sendAmountEmail(body) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/configurations/dispersion/send`,
        data: body,
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getPatchNotes() {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/configurations/notes/get`,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async updatePatchNotes(body) {
    try {
      await axios({
        method: "POST",
        url: `${URL}/configurations/notes/update`,
        data: body,
      });
      notify(
        "Las notas de la version fueron actualizadas correctamente",
        "success"
      );
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  //* @DESC Dashboards* //

  static async getGenderDataByCompanyMonth(companyId, month, maxMonths = 1) {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/dashboard/gender/${companyId}/${month}/${maxMonths}`,
      });

      return JSON.parse(decrypt(result.data));
    } catch (error) {
      return {};
    }
  }

  static async getScholarshipAmountDataByCompanyMonth(
    companyId,
    month,
    maxMonths = 1
  ) {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/dashboard/scholarshipAmount/${companyId}/${month}/${maxMonths}`,
      });

      return JSON.parse(decrypt(result.data));
    } catch (error) {
      return { companyId };
    }
  }

  static async getTopUniversitiesByCompanyMonth(
    companyId,
    month,
    maxMonths = 1
  ) {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/dashboard/topUniversities/${companyId}/${month}/${maxMonths}`,
      });

      return JSON.parse(decrypt(result.data));
    } catch (error) {
      return { companyId };
    }
  }

  static async getInternStatusDataByCompanyMonth(
    companyId,
    month,
    maxMonths = 1
  ) {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/dashboard/internStatus/${companyId}/${month}/${maxMonths}`,
      });

      return JSON.parse(decrypt(result.data));
    } catch (error) {
      return [
        {
          companyId: companyId,
          month: month,
        },
      ];
    }
  }

  static async getInoviceRecordsByMonths(companyId, month, maxMonths = 1) {
    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/dashboard/inovicesRecords/${companyId}/${month}/${maxMonths}`,
      });

      return JSON.parse(decrypt(result.data));
    } catch (error) {
      return [
        {
          companyId: companyId,
          month: month,
        },
      ];
    }
  }

  // * @DESC Errors * //

  static async getBillingErrors(body) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/error/get/billing`,
        data: body,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getInsuranceErrors(body) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/error/get/insurance`,
        data: body,
      });

      const insuranceErrors = JSON.parse(decrypt(result.data));

      if (!insuranceErrors.length) {
        notify("No se encontraron datos en las fechas seleccionadas", "error");
      }

      return insuranceErrors;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return [];
    }
  }

  // * @DESC Dispersion * //

  static async createDispersionRecords(body) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/dispersion/create/records`,
        data: body,
      });
      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      throw new Error("Error al generar los registros de dispersión");
    }
  }

  static async getDispersionRecorsdByPayDay(
    payDay,
    dispersionType,
    invoiceNumber,
    getAllItems = false,
    recordStatus = ""
  ) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/dispersionRecords`,
        data: {
          payDay,
          dispersionType,
          invoiceNumber,
          recordStatus: recordStatus,
          getAllItems,
        },
      });

      return JSON.parse(decrypt(result.data));
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getDispersionPendingRecorsdByEmail(email) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/pendingRecords/dispersion`,
        data: {
          email,
        },
      });

      return JSON.parse(decrypt(result.data));
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async saveLetter(data) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/saveLetter`,
        data: {
          data,
        },
      });

      return JSON.parse(result.data);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getLetter(data) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/getLetter`,
        data: {
          data,
        },
      });

      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getDates(data) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/getDates`,
        data: {
          data,
        },
      });

      return result.data;
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async setActionToDisperseByFileId(id, action, status = "") {
    try {
      await axios({
        method: "POST",
        url: `${URL}/pendingRecords/action`,
        data: {
          id,
          action,
          status: status,
        },
      });
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async getDispersionByEmailAndDate(contact, startDate, endDate) {
    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/finishedRecords/range`,
        data: {
          email: contact.email,
          dates: {
            startDate,
            endDate,
          },
        },
      });

      return {
        dispersions: JSON.parse(decrypt(result.data)),
        contact: contact,
      };
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
      return {
        dispersions: [],
        contact: contact,
      };
    }
  }

  // * @DESC DisabledCompanies * //
  static async getDisabledCompanies(data) {
    let companies = [];

    try {
      let result = await axios({
        method: "POST",
        url: `${URL}/disabled/companies`,
        data: data || {},
      });
      console.log(decrypt(result));
      companies = JSON.parse(decrypt(result.data));
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }

    return {
      companies,
    };
  }

  static async getDesactivationHistoryRecords(companyId) {
    let records = [];

    try {
      let result = await axios({
        method: "GET",
        url: `${URL}/disabled/history/records/${companyId}`,
      });

      records = JSON.parse(decrypt(result.data));
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }

    return {
      records,
    };
  }

  static async createDesactivationHistoryRecord(data) {
    console.log(data);
    try {
      let response = await axios({
        method: "POST",
        url: `${URL}/disabled/history/records`,
        data: encrypt(data),
      });

      notify(response.data.message, "success");
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }

  static async removeCompanyFromDesactivation(data) {
    try {
      let response = await axios({
        method: "POST",
        url: `${URL}/disabled/remove/company`,
        data: encrypt(data),
      });

      notify(response.data.message, "success");
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  }
}

const verifyDocumentTypeHeader = (type) => {
  let typeHeader = {
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    doc: "application/msword",
    txt: "text/plain",
    text: "text/plain",
    pdf: "application/pdf",
    xml: "text/xml",
    json: "application/json",
  };
  return typeHeader[type] || "image/*";
};

// * @DESC Utils * //
function getExtension(fileName) {
  let basename = fileName.split(/[\\/]/).pop();
  let pos = basename.lastIndexOf(".");

  if (basename === "" || pos < 1) {
    return "";
  }

  return basename.slice(pos + 1);
}
