import { notify } from "../utils/notify";
import { pdf } from "@react-pdf/renderer";
import { EthicCodeFile } from "../custom/SignatureFiles/EthicCodeFile";
import { ConfidentialityAgreements } from "../custom/SignatureFiles/ConfidentialityAgreements";
import Methods from "./https";
const URL = process.env.REACT_APP_SOCKET_URL;
const axios = require("axios");
const uuid = require("uuid");

export const sendITESMdocs = async (contract) => {
  try {
    await Promise.all([
      sendConfidentialityAgreements(contract),
      ethicDocument(contract),
    ]);
  } catch (error) {
    console.log(error);
  }
};

export const sendConfidentialityAgreements = async (contract) => {
  const confidentialDataAux = {
    firstname: contract.internFirstName,
    father_lastname: contract.internFatherLastname,
    mother_lastname: contract.internMotherLastname,
    email: contract.internEmail || contract.email,
    phone: contract.internPhone,
    docName: "Carta de confidencialidad",
    docId: uuid.v1(),
  };

  let fullName =
    confidentialDataAux.firstname +
    " " +
    confidentialDataAux.father_lastname +
    " " +
    confidentialDataAux.mother_lastname;
  const docAux = await (
    <ConfidentialityAgreements
      internName={fullName}
      companyName={contract.companyName?.trim()}
    />
  );
  const asPdf = pdf([]);
  asPdf.updateContainer(docAux);
  const blob = await asPdf.toBlob();
  await sendDocuments(blob, confidentialDataAux);
  await setDocStatus(contract.internId, [
    { updateKey: "confidentialityDocStatus", updateValue: "Pendiente" },
    {
      updateKey: "confidentialityDocId",
      updateValue: confidentialDataAux.docId,
    },
  ]);
};

export const ethicDocument = async (contract) => {
  const ethicCodeDataAux = {
    firstname: contract.internFirstName,
    father_lastname: contract.internFatherLastname,
    mother_lastname: contract.internMotherLastname,
    email: contract.internEmail || contract.email,
    phone: contract.internPhone,
    docName: "Código de ética",
    docId: uuid.v1(),
  };

  let fullName =
    ethicCodeDataAux.firstname +
    " " +
    ethicCodeDataAux.father_lastname +
    " " +
    ethicCodeDataAux.mother_lastname;
  const docAux = await (
    <EthicCodeFile
      fullName={fullName}
      internStartDate={contract.internStartDate}
    />
  );
  const asPdf = pdf([]);
  asPdf.updateContainer(docAux);
  const blob = await asPdf.toBlob();
  await sendDocuments(blob, ethicCodeDataAux);
  await setDocStatus(contract.internId, [
    { updateKey: "ethicCodeDocStatus", updateValue: "Pendiente" },
    { updateKey: "ethicCodeDocId", updateValue: ethicCodeDataAux.docId },
  ]);
};

export const sendDocuments = async (blob, data) => {
  let blobBuffer = await blob.arrayBuffer();

  let buffer = Buffer.from(blobBuffer);

  let phone = data.phone || data.internPhone || "";
  let phoneLada = "+52" + phone;
  let phoneNumber = phoneLada;

  let signers = [
    {
      name: data.firstname || "",
      lastNameP: data.father_lastname || "",
      lastNameM: data.mother_lastname || "",
      email: data.email || data.internEmail,
      phone: phoneNumber,
      rfc: "",
    },
  ];

  const body = {
    signers: signers,
    rfcUser: false,
    docName: data.docName,
    docId: data.docId,
    doc: buffer,
    hash: data.docId,
    URL: URL + "/create/signature",
  };
  try {
    await axios({
      method: "POST",
      url: `${URL}/send/signature`,
      data: body,
    });
  } catch (error) {
    notify(
      `Ocurrió algo al momento de mandar la documentación: ${data.docName}`,
      "error"
    );
  }
};

const setDocStatus = async (internId, attributes) => {
  let internData = {
    _id: internId,
    attributes,
  };
  await Methods.updateIntern(internData);
};

export const setITESMSignedDocs = async (internId) => {
  try {
    let internData = {
      _id: internId,
      attributes: [
        { updateKey: "ethicCodeDocStatus", updateValue: "Firmado" },
        { updateKey: "confidentialityDocStatus", updateValue: "Firmado" },
        { updateKey: "isRenewed", updateValue: true },
      ],
    };
    await Methods.updateIntern(internData);
  } catch (error) {
    console.log(error);
  }
};
