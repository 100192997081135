import React, { useState, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Row,
  Col,
  Button,
  Form,
  Image,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import LogoCaintra from "../../assets/caintra_logo_login.png";
import InternsLogo from "../../assets/logo_interns.png";
import ValidateEmail from "../Register/components/ValidateEmail.jsx";
import { Auth } from "aws-amplify";
import ForgotPassword from "./components/ForgotPassword.jsx";
import RegisterUser from "./components/RegisterUser.jsx";
import Methods from "../../utils/https";
import { Link } from "react-router-dom";
import { notify } from "../../utils/notify";
import { IoMailOutline, IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import whatsapp from "../../assets/whatsapp.png";
import "./Login.css";
let isExecuting = false;
let attempts = 0;
const maxAttempts = 200;

export default function Login() {
  const user = useContext(UserContext);
  const [emailToValidate, setEmailToValidate] = useState(undefined);
  const [currentTab, setCurrentTab] = useState("login");
  const [isLogginIn, setIsLogginIn] = useState(false);
  const [displayRegister, setDisplayRegister] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [seePass, setSeePass] = useState(false);

  window.addEventListener("message", (event) => {
    if (!isExecuting) {
      isExecuting = true;
      executeLogin(event.data);
    }
  });

  function executeLogin(data) {
    if (attempts < maxAttempts) {
      console.log(`Intento #${attempts + 1}: EVENTO RECIBIDO!!!!!!!`, data);
      login({ email: data.user, password: data.password });
      attempts++;

      setTimeout(() => executeLogin(data), 500);
    } else {
      console.log("Se alcanzó el límite de intentos, deteniendo el proceso.");
      isExecuting = false;
    }
  }

  const login = async (userData) => {
    setIsLogginIn(true);
    setCurrentEmail(userData.email);
    try {
      await Auth.signIn(userData.email, userData.password);
      let currentUser = await Auth.currentSession();
      let jwt = currentUser.getIdToken().getJwtToken();
      await user.onLoad(userData.email, jwt);
      setIsLogginIn(false);
    } catch (e) {
      switch (e.code) {
        case "UserNotFoundException":
          let isRegistered = await verifyRegister(
            userData.email,
            userData.password
          );
          if (isRegistered) {
            setDisplayRegister(true);
            setIsLogginIn(false);
            break;
          } else {
            break;
          }
        case "NotAuthorizedException":
          notify("La contraseña que ingresaste es incorrecta", "error");
          break;
        case "UserNotConfirmedException":
          notify("¡No has validado tu correo electrónico!", "error");
          setEmailToValidate(userData.email);
          setCurrentTab("validateEmail");
          break;
        default:
          notify(e?.response?.data?.message, "error");
          break;
      }
      setIsLogginIn(false);
    }
  };

  const verifyRegister = async (email, password) => {
    let data = {
      email: email,
      password: password,
    };
    let result = await Methods.getUserByEmail(data);
    result = result.data;
    if (result.isApproved && result.isRegistered) {
      return true;
    } else if (!result.isRegistered) {
      notify(
        "El correo electrónico ingresado no se encuentra registrado o la contraseña es incorrecta",
        "error"
      );
      return false;
    } else if (!result.isApproved) {
      notify("Este usuario no ha sido aprobado", "error");
      return false;
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    const user = {
      email: event.target.elements.email.value.trim(),
      password: event.target.elements.password.value,
    };

    const emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/
    );

    !emailRegex.test(user.email)
      ? notify("El correo que ingresaste es inválido", "error")
      : login(user);
  };

  const getSeePass = () =>
    !seePass ? (
      <IoEyeOffOutline
        onClick={() => {
          setSeePass(!seePass);
        }}
        class="input-icon see-password"
      />
    ) : (
      <IoEyeOutline
        onClick={() => {
          setSeePass(!seePass);
        }}
        class="input-icon see-password"
      />
    );

  return (
    <div className="login">
      <div className="blurer">
        <Row style={{ height: "100%" }}>
          <Col className="form-container">
            <div className="form-box">
              <div className="form-width">
                <Row className="row-logo">
                  <Col>
                    <div className="logos-container">
                      <Image src={InternsLogo} className="InternsLogo" />
                      <a href="#" className="divider"></a>
                      <Image src={LogoCaintra} className="logoCaintra" />
                    </div>
                  </Col>
                </Row>
                <Row className="row-selection">
                  <a
                    href="#"
                    onClick={() => setCurrentTab("login")}
                    className="logInBtn"
                  >
                    <strong>Log in</strong>
                  </a>
                  <Link to="/register">Sign up</Link>
                </Row>
                {currentTab == "login" && (
                  <Form onSubmit={handleLogin}>
                    <Form.Group controlId="email">
                      <InputGroup>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Correo"
                          onChange={(e) =>
                            (e.target.value = e.target.value.toLowerCase())
                          }
                          className="example"
                        />
                        <InputGroup.Append>
                          <InputGroup.Text className="example">
                            <IoMailOutline class="input-icon" />
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="password">
                      <InputGroup>
                        <Form.Control
                          required
                          type={!seePass ? "password" : "text"}
                          placeholder="Contraseña"
                          style={{ backgroundColor: "#f1f1f1 !important" }}
                        />
                        <InputGroup.Append>
                          <InputGroup.Text>{getSeePass()}</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                    <div className="forgot-container">
                      <a
                        className="forgot-password"
                        onClick={() => setCurrentTab("forgotPassword")}
                      >
                        <small>¿Olvidaste tu contraseña?</small>
                      </a>
                    </div>
                    <Button
                      className="login-btn"
                      type="submit"
                      onClick={() => {
                        localStorage.setItem("logout", false);
                      }}
                    >
                      {!isLogginIn ? (
                        "Iniciar Sesión"
                      ) : (
                        <Spinner animation="border" role="status"></Spinner>
                      )}
                    </Button>
                    <div className="need-help-container">
                      <div className="need-help">
                        ¿Necesitas ayuda?
                        <Image src={whatsapp} className="whatsapp-icon" />
                      </div>
                    </div>
                  </Form>
                )}

                {currentTab === "forgotPassword" && (
                  <ForgotPassword setCurrentTab={setCurrentTab} />
                )}

                {currentTab === "validateEmail" && (
                  <ValidateEmail emailToValidate={emailToValidate} />
                )}
                {displayRegister && (
                  <RegisterUser
                    setDisplayRegister={setDisplayRegister}
                    displayRegister={displayRegister}
                    currentEmail={currentEmail}
                  />
                )}
              </div>
            </div>
          </Col>
          <Col md={7} className="background-container"></Col>
        </Row>
      </div>
    </div>
  );
}
